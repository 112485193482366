@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #1e293b;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

  @layer utilities {

    .clip-page {

        clip-path: polygon(0 0, 10% 0, 100% 100%, 0 calc(100% - 6vw));

    }

  }